<script setup>
import { JTab } from "media-flow-ui"
import { ref, computed } from "vue"

import TabData from "~~/models/layout/tabs/TabData"
import { usePageBuilder } from "~/stores/page-builder"
import { JUSTIFY_CONTENT_OPTIONS } from "~~/constants/style"

import { formatIconStyle } from "~/helpers/icon/icon"
import { formatComponentStyle } from "@/models/layout/style/component"

const pageBuilderStore = usePageBuilder()

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null,
  },
})

const route = useRoute()
const router = useRouter()

const tabsData = ref([])
const tabActiveIndex = ref(0)

const { data } = toRefs(props)

// Compute properties with null checks
const isChangeOnHover = computed(() => data.value?.changeOnHover || false)
const allowToSaveTabOnUrl = computed(
  () => data.value?.props?.allowToSaveTabOnUrl || false
)

const style = computed(() =>
  formatComponentStyle({
    alignment: data.value?.style?.alignment || "left",
    gap: data.value?.style?.gap || 0,
    width: data.value?.style?.width || "auto",
  })
)

const tabItemStyle = computed(() =>
  formatComponentStyle({
    ...data.value?.style,
    borderProperties: {
      ...data.value?.style?.borderProperties,
      show: true,
    },
    width: null,
    gap: null,
    borderStyle: "solid",
    textAlign: data.value?.style?.alignment || "left",
  })
)

const iconsStyle = computed(() => formatIconStyle(data.value?.icon || {}))

const wrapperClasses = computed(
  () =>
    `${JUSTIFY_CONTENT_OPTIONS[data.value?.style?.alignment] || "justify-left"}`
)

// Use useAsyncData to fetch tabsData, ensuring server-side rendering
const {
  data: fetchedTabsData,
  pending,
  error,
} = await useAsyncData("fetchTabsData", async () => {
  const tabsDataArray = []

  // Check if data.value.tabs exists and is an array
  if (Array.isArray(data.value?.tabs)) {
    // Iterate over data.value.tabs
    for (const [tabsIndex, tab] of data.value.tabs.entries()) {
      if (tab.isDynamic) {
        const queryId = tab.settings?.query?.id
        const params = {
          take: tab.settings?.take || tab.settings?.limit,
        }

        let response
        try {
          const res = await pageBuilderStore.actionGetQueryDataInitClient(
            queryId,
            params,
            null,
            null,
            props.rowOrder
          )

          if (res) {
            response = res.response
          }
        } catch (error) {
          console.error(error)
        }

        if (!response) continue

        const dynamicTabs = response.data.map((rawTab, tabIndex) => {
          const tabData = new TabData()

          // Set layout data for each dynamic tab
          tabData.setTabLayout(tab.layout[0])

          tabData.setTabName(rawTab.title)
          tabData.setTabContentId(rawTab.contentId)

          tabData.setTabManagement(tabsIndex, tabIndex)

          return tabData
        })

        tabsDataArray.push(...dynamicTabs)
      } else {
        // Handle static tab
        const tabData = new TabData()
        tabData.setTabName(tab.data?.name || `Tab ${tabsIndex + 1}`)
        tabData.setTabLinkData(tab.data?.linkData, data.value?.changeOnHover)
        tabData.setTabLayout(tab.layout[0])

        tabsDataArray.push(tabData)
      }
    }
  } else {
    console.warn("Tabs data is not an array or is undefined")
  }

  return tabsDataArray
})

// Assign fetched data to tabsData
if (fetchedTabsData.value && fetchedTabsData.value.length > 0) {
  tabsData.value = fetchedTabsData.value

  // After tabsData is populated, set tabActiveIndex and loaded flags
  const tabNameByQueryUrl = route.query.tab

  let indexFound = 0

  if (tabNameByQueryUrl) {
    indexFound = tabsData.value.findIndex((t) => t.name === tabNameByQueryUrl)

    indexFound = indexFound === -1 ? 0 : indexFound

    if (indexFound !== -1) {
      tabActiveIndex.value = indexFound
    }
  }

  tabsData.value.forEach((t, i) => {
    t.loaded = i === indexFound
  })
} else {
  // Handle case where tabsData is empty or null
  tabsData.value = [] // Ensure tabsData is an array
  console.warn("No tabs data available")
}

// Tab click handler
const tabClick = ({ tabName: tab, tabIndex }) => {
  if (!tabsData.value[tabIndex]) return

  tabsData.value[tabIndex].loaded = true
  tabActiveIndex.value = tabIndex

  if (isChangeOnHover.value || !allowToSaveTabOnUrl.value) return

  router.replace({
    query: {
      ...route.query,
      tab,
    },
  })
}

// Keep the commented code as requested
//grid grid-cols-1
// grid-cols-1
// grid-cols-2
// grid-cols-3
// grid-cols-4
// grid-cols-5
// grid-cols-6
// grid-cols-7
// grid-cols-8
// grid-cols-9
// grid-cols-10

// md:grid-cols-1
// md:grid-cols-2
// md:grid-cols-3
// md:grid-cols-4
// md:grid-cols-5
// md:grid-cols-6
// md:grid-cols-7
// md:grid-cols-8
// md:grid-cols-9
// md:grid-cols-10
</script>

<template>
  <div>
    <!-- <pre style="color: red">
      {{tabItemStyle}}
    </pre> -->
    <JTab
      :tabs="tabsData"
      :onTabClickCallback="tabClick"
      :activeTab="tabActiveIndex"
      :change-on-hover="isChangeOnHover"
      :wrapper-classes="wrapperClasses"
      :tab-item-style="tabItemStyle"
      :layout-type="data.props.layoutType"
      :is-vertical="data.props.isVertical"
      :is-carousel="data.props.isCarousel"
      :wrapper-style="style"
      :icons-style="iconsStyle"
    >
      <template #tab="data">
        <Widget v-if="data.tab.loaded" :data="data.tab" :rowOrder="rowOrder" />
      </template>
    </JTab>
  </div>
</template>

<style></style>
